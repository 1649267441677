<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <h4>ค่ายเกมส์</h4>
    <b-card>
      <h4>Slot</h4>
      <b-row match-height>
        <b-col sm="4" md="3" lg="2" v-for="item in products.filter(item => item.category === 'EGAMES')">
          <b-card no-body class="border overflow-hidden" style="border-radius: 16px;">
            <video v-if="item.img_url.includes('mp4')" class="w-100 h-100" autoplay muted loop playsinline style="
                  pointer-events: none;
                  width: 100%;
                  height: 100%;
                  object-fit: cover; 
                  top: 0;
                  left: 0;
                ">
              <source :src="`${item.img_url}`" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img v-else class="w-100 h-100" :src="item.img_url.startsWith('http') ? item.img_url : `${item.img_url}`"
              style="object-fit: cover; ">
            <div class="mt-1 text-truncate px-1 pb-1">
              <h6>{{ item.productName }}</h6>
              <b-form-checkbox @change="changeStatus(item)" v-model="item.status" name="check-button" switch :value="1"
                :unchecked-value="0"></b-form-checkbox>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>CASINO</h4>
      <b-row match-height>
        <b-col sm="4" md="3" lg="2" v-for="item in products.filter(item => item.category === 'LIVECASINO')">
          <b-card no-body class="border overflow-hidden" style="border-radius: 16px;">
            <video v-if="item.img_url.includes('mp4')" class="w-100 h-100" autoplay muted loop playsinline style="
                  pointer-events: none;
                  width: 100%;
                  height: 100%;
                  object-fit: cover; 
                  top: 0;
                  left: 0;
                ">
              <source :src="`${item.img_url}`" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img v-else class="w-100 h-100" :src="item.img_url.startsWith('http') ? item.img_url : `${item.img_url}`"
              style="object-fit: cover; ">
            <div class="mt-1 text-truncate px-1 pb-1">
              <h6>{{ item.productName }}</h6>
              <b-form-checkbox @change="changeStatus(item)" v-model="item.status" name="check-button" switch :value="1"
                :unchecked-value="0"></b-form-checkbox>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>SPORT</h4>
      <b-row match-height>
        <b-col sm="4" md="3" lg="2" v-for="item in products.filter(item => item.category === 'SPORT')">
          <b-card no-body class="border overflow-hidden" style="border-radius: 16px;">
            <video v-if="item.img_url.includes('mp4')" class="w-100 h-100" autoplay muted loop playsinline style="
                  pointer-events: none;
                  width: 100%;
                  height: 100%;
                  object-fit: cover; 
                  top: 0;
                  left: 0;
                ">
              <source :src="`${item.img_url}`" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img v-else class="w-100 h-100" :src="item.img_url.startsWith('http') ? item.img_url : `${item.img_url}`"
              style="object-fit: cover; ">
            <div class="mt-1 text-truncate px-1 pb-1">
              <h6>{{ item.productName }}</h6>
              <b-form-checkbox @change="changeStatus(item)" v-model="item.status" name="check-button" switch :value="1"
                :unchecked-value="0"></b-form-checkbox>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>


<script>
import {
  BFormCheckbox, BRow, BCol, BModal, BFormInput, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    BFormCheckbox, BRow, BCol, BModal,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BCard,
    BCardTitle,
    BCardBody,
    BOverlay,
    BIconController,
    BButton,
    flatPickr,
  },
  data: () => ({
    search_val: '',
    perPage: 10,
    pageOptions: [10, 15, 20, 'all'],
    totalRows: 1,
    page: 1,
    exportPassword: '',
    fields: [
      { key: 'detail', label: 'รายละเอียด' },
      { key: 'username', label: 'ยูสเซอร์' },
      // { key: 'user_tel', label: 'เบอร์โทรศัพท์' },
      { key: 'bank', label: 'ธนาคารสมาชิก' },
      { key: 'user_acc_no', label: 'บัญชีธนาคารสมาชิก' },
      { key: 'user_acc_name', label: 'ชื่อธนาคารสมาชิก' },
      { key: 'pro_name', label: 'โปรโมชั่น' },
      { key: 'amount', label: 'จำนวน' },
      { key: 'to_acc', label: 'ธนาคารที่ลูกค้าโอนเข้ามา' },
      { key: 'created_time', label: 'เวลาจากธนาคาร' },
      { key: 'updated_time', label: 'เวลาที่เข้า ag' },
      { key: 'from', label: 'ทำรายการโดย' },
    ],
    items: [],
    isLoading: false,
    dateStart: moment().tz('Asia/Bangkok').clone().subtract(20, 'days')
      .format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    URLs: null,
    currency: 'THB',
    currencyOPs: ['THB', 'KRW'],
    products: []
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async changeStatus(item) {
      this.isLoading = true
      try {
        const { data } = await this.$http.post(`/games/products/changeStatus`, item)

        this.getData();
        // console.log(data)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getData() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get(`/games/products`)
        this.products = data
        // console.log(data)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async checkExportPassword() {
      const obj = {
        password: this.exportPassword,
      }
      this.$http
        .post('/export/checkpass', obj)
        .then(response => {
          if (response.data.status === true) {
            this.exportData()
          } else {
            this.$swal({
              icon: 'error',
              title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
              text: 'รหัสผ่านไม่ถูกต้อง',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
        .catch(error => console.log(error))
    },
    // async checkExportPassword() {
    //   if (this.exportPassword === 'gSEnn9hEH6Ts5wH') {
    //     this.exportData()
    //   } else {
    //     this.$swal({
    //       icon: 'error',
    //       title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
    //       text: 'รหัสผ่านไม่ถูกต้อง',
    //       customClass: {
    //         confirmButton: 'btn btn-primary',
    //       },
    //     })
    //   }
    // },
    async exportData() {
      try {
        const { data } = await this.$http.get('/export/deposits', {
          responseType: 'blob',
          params: {
            page: this.page, perPage: this.perPage, dateStart: this.dateStart, dateEnd: this.dateEnd,
          },
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([data]),
        )
        // link.setAttribute(`download', 'Export Deposit Date ${this.dateStart} - ${this.dateEnd}.xlsx`)
        link.setAttribute('download', 'ReportDepsits.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (error) {

      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
